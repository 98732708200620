<template>
    <div class="block">
        <img src="@/assets/img/network.png" alt="">
        <div class="text">暂无网络，请检查您的网络设置</div>
        <div class="button" @click="onClickLeft">返回</div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    mounted() {
        this.$store.commit("hideTabbar");
    },
    methods: {
        // 返回上一页
        onClickLeft() {
            window.location.href = sessionStorage.locationUrl
        },
    },
}
</script>
<style scoped>
.block{width: 100%;text-align: center;padding-top: 30%;}
    img{width: 260px;height: 260px;margin: auto;display: block;}
    .text{color: #1a1a1a;margin-top: 40px;}
    .button{background-color: #0ABBB9;color: #fff;width: 300px;line-height: 70px;border-radius: 70px;margin: 30px auto;}
</style>